import { Customer, CustomerShort } from "./customers";
import {
  Address,
  Option,
  OrderType,
  SidebarPrefix,
  SyncStatus,
} from "./general";
import { PaymentMethodGateway } from "./payment";
import { StoreShort } from "./stores";

export interface Statement {
  customer: CustomerShort;
  account_manager: CustomerShort;
  status: StatementStatus;
  due_balance: number;
  overdue_balance: number;
  overdue_days: number;
  delivery_address: Address;
  billing_address: Address;
}

export interface StatementActivity {
  activity_id: number;
  activity_type: StatementActivityType;
  invoice_id: string;
  reference: string;
  invoice_due_date: string;
  invoice_amount: number;
  payment_amount: number;
  payment_method: {
    id: number;
    name: string;
    gateway: PaymentMethodGateway;
  };
  balance: number;
  date: string;
  order_type: OrderType;
  due_balance: number;
  pricing_method: string;
  sync_status: string;
  sales_manager: Customer;
  customer: Customer;
  paid_status: string;
  target_date: string;
}

export interface StatementOutstanding {
  id: number;
  customer: CustomerShort;
  account_manager: CustomerShort;
  type: OrderType;
  payment_method: {
    id: number;
    name: string;
    gateway: PaymentMethodGateway;
  };
  store: StoreShort;
  status: StatementStatus;
  created_at: string;
  invoice_number: number;
  invoice_due_date: string;
  paid_value: number;
  total_value: number;
  balance: number;
  sync_status: SyncStatus;
  reference: string;
  po_reference: string;
  is_picked: boolean;
  is_zero_priced: boolean;
  invoice_is_sent: boolean;
  invoice_is_viewed: boolean;
}

export enum StatementStatus {
  DRAFT = "draft",
  SENT = "sent",
  PAID = "paid",
  OVERDUE = "overdue",
  COMPLETED = "completed",
  AWAITING = "awaiting_payment",
  ACTIVE = "active",
  ARCHIVE = "archived",
}

export enum StatementActivityType {
  PAYMENT = "payment",
  PARTIAL_PAYMENT = "partial_payment",
  ORDER = "order",
}

export enum PurchaseStatementActivityType {
  PAYMENT = "payment",
  PARTIAL_PAYMENT = "partial_payment",
  PURCHASE_ORDER = "purchaseOrder",
}

export enum StatementViewMode {
  ACTIVITY = "activity-mode",
  OUTSTANDING = "outstanding-mode",
  PURCHASE_OUTSTANDING = "purchase-outstanding-mode",
  PURCHASE_ACTIVITY = "purchase-activity-mode",
}

export interface StatementManualPayload {
  send_email: boolean;
  customers: number[];
  date_from: string;
  date_to: string;
  email_subject?: string;
  email_content?: string;
}

export interface StatementAuto {
  id?: number;
  name: string;
  send_email: boolean;
  frequency_type: StatementFrequency;
  time: string;
  email_subject: string;
  email_content: string;
  customers?: CustomerShort[];
  week_day?: number;
  month_day?: number;
  statement_type: string;
}

export interface StatementAutoPayload {
  name: string;
  send_email: boolean;
  customers: Option[];
  frequency_type: StatementFrequency;
  week_day?: number;
  month_day?: number;
  time: string;
  email_subject: string;
  email_content: string;
  statement_type: string;
}

export enum StatementFrequency {
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

// SIDEBAR
export const SidebarPage = {
  STATEMENTS_LIST: `${SidebarPrefix.STATEMENTS}-list`,
  STATEMENTS_AUTO: `${SidebarPrefix.STATEMENTS}-auto`,
  STATEMENTS_FILTERS: `${SidebarPrefix.STATEMENTS}-filters`,
};
